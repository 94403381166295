.description {
    font-family: 'Raleway', sans-serif;
    width: 80%;
    margin: auto;
}

@media all and (min-width: 600px){
    .description {
        width: 40%;
    }
}