/*
    Colors: 
    * Dim Gray = #65655E
    * Oxford Blue = #020E3C
    * Marigold = #ECA72C
    * White = #FFFFFF
    * Carnelian = #B80018

    Fonts: 
    * font-family: 'Montserrat', sans-serif
    * font-family: 'Raleway', sans-serif

*/

* {
    box-sizing: border-box;
}