.navbar {
    background-color: #65655e;
    margin-top: 0px;
}
.navlinks {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.navlinks li {
    list-style: none;
    flex: 1;
    text-align: center;  
}

.navlinks a {
    text-decoration: none;
    color: #FFFFFF;
    font-family: 'Raleway', sans-serif;
}

.navlinks a:visited {
    color: #FFFFFF;
}

.navlinks li:hover, .navlinks li:active {
    background-color: #b80018;
}