.page-nav {
    width: 70vw;
    margin: auto;
}
.pagination {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0px;
}

.pagination li {
    list-style: none;
    margin: 0px 10px 30px 10px;
}