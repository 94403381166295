.question h3 {
    font-family: 'Montserrat', sans-serif;
    color: #020E3C;
}

.question p {
    font-family: 'Raleway', sans-serif;
}

.question a {
    color:#020E3C;
}

.question a:hover, .question a:visited {
    color: #B80018;
}