.union-link {
    text-align: center;
    font-family: 'Raleway', sans-serif;
}

.union-link a{
    color:#020E3C;
}

.union-link a:hover, .union-link a:visited {
    color: #B80018;
}